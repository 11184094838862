/*!
 * Start Bootstrap - Agency Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

var docElem        = document.documentElement,
	header         = document.querySelector('.navbar-default'),
	rings          = document.getElementById('intro-rings'),
	//didScroll = false,
	stopRingsOn    = 20,
	changeHeaderOn = 300,
	direction      = "down",
	lastScrollTop  = 0;

// jQuery for page scrolling feature - requires jQuery Easing plugin
$(function () {
	$('a.page-scroll').bind('click', function (event) {
		var $anchor = $(this);
		$('html, body').stop().animate({
			scrollTop : $($anchor.attr('href')).offset().top
		}, 1500, 'easeInOutExpo');
		event.preventDefault();
	});

	initIntro();
});

// Highlight the top nav as scrolling occurs
/*$('body').scrollspy({
 target : '.navbar-fixed-top'
 })*/

// Closes the Responsive Menu on Menu Item Click
$('.navbar-collapse ul li a').click(function () {
	$('.navbar-toggle:visible').click();
});

function initIntro() {
	return;
	var windowWidth = jQuery(window).width();

	$("#intro-slider").owlCarousel({

		navigation      : true, // Show next and prev buttons
		slideSpeed      : 300,
		paginationSpeed : 400,
		singleItem      : true

		// "singleItem:true" is a shortcut for:
		// items : 1,
		// itemsDesktop : false,
		// itemsDesktopSmall : false,
		// itemsTablet: false,
		// itemsMobile : false

	});
	/*if(windowWidth >= 1024) {

	 window.addEventListener( 'scroll', function( event ) {
	 if( !didScroll ) {
	 didScroll = true;
	 //setTimeout( scrollPage, 250 );
	 }
	 }, false );
	 }*/

	// RINGS

	var intro = jQuery('.js-height-full');
	var offset = 220 + 25;
	/*margin-top and bottom*/
	//var introHeight = jQuery(window).height();
	//var introWidth = intro.width();

	var viewBoxHeight = jQuery(window).height() - offset;
	var viewBoxWidth = jQuery(".page-section.partner > .container").width();

	if (viewBoxWidth / viewBoxHeight > 1.5) viewBoxWidth = viewBoxWidth * 1.5;

	var viewbox = ("0 0 " + viewBoxWidth + " " + viewBoxHeight);

	document.getElementById("svg-rings").setAttribute("viewBox", viewbox);


	var elements = ['rings', 'claim'];
	for (var ele in elements) {
		ele = elements[ele];
		ele = document.getElementById(ele);
		if (ele)
			positionEleToCenter(ele);
	}
	//
	//var circles = document.getElementById("rings").getBoundingClientRect();
	//var spaceForOpenRings = circles.height;
	//
	//var ringParagraphsHeight = 200; //TODO dynamic
	//if (windowWidth < 1280) {
	//	ringParagraphsHeight = 400;
	//}
	//if(windowWidth >= 1024 && (jQuery(window).height() - document.getElementById("intro").getBoundingClientRect().height < circles.height + ringParagraphsHeight)) {
	//	intro.height(introHeight + spaceForOpenRings + ringParagraphsHeight);
	//}

	var ring1 = Snap("#ring1");
	var ring2 = Snap("#ring2");
	var ring3 = Snap("#ring3");
	var blendInRadius = 50;

	ring1.attr({cx : 147.082, cy : 119.322 - blendInRadius, opacity : 0});
	ring2.attr({cx : 119.322 - blendInRadius, cy : 160.574, opacity : 0});
	ring3.attr({cx : 173.972 + blendInRadius, cy : 159.4, opacity : 0});
}

function scrollPage() {
	var sy = scrollY();

	if (sy > lastScrollTop) {
		direction = "down";
	} else {
		direction = "up";
	}
	lastScrollTop = sy;

	if (sy >= stopRingsOn) {
		//var animateRings = false;
		//if(!classie.hasClass(rings, "animation-off")) animateRings = true;

		//classie.add( rings, 'animation-off' );
		//if(animateRings) openRings(true);
		openRings(true)
	} else {

		//if(direction == "up" && classie.hasClass(rings, "animation-off"))
		//classie.remove( rings, 'animation-off' );
		openRings(false);
	}

	//didScroll = false;
}

function scrollY() {
	return window.pageYOffset || docElem.scrollTop;
}

function positionEleToCenter(element) {
	var bbox = element.getBBox();
	var svg = document.getElementById('svg-rings');

	var viewBox = svg.getAttribute('viewBox');
	viewBox = viewBox.split(' ');

	var cx = parseFloat(viewBox[0]) + (parseFloat(viewBox[2]) / 2);
	var x = cx - (bbox.width / 2);
	if (element.getAttribute("id") == "claim") {
		x += (bbox.width / 2.75);
	}
	var y = 100;
	var matrix = '1 0 0 1 ' + x + ' ' + y;

	element.setAttribute('transform', 'matrix(' + matrix + ')');

}

var openRings = function (bool) {
	var blendInRadius = 50;
	var ring1 = Snap("#ring1");
	var ring2 = Snap("#ring2");
	var ring3 = Snap("#ring3");
	//var claim = Snap("#claim");
	//var circles = document.getElementById("rings").getBoundingClientRect();

	//var offset = 220 + 30; /*margin-top and bottom*/
	//var introHeight = jQuery(window).height() - offset;
	//var introWidth = jQuery(".page-section.partner > .container").width();
	//if(introWidth/introHeight > 1.5) introWidth = introWidth * 1.5;

	if (bool) {
		ring1.animate({r : 116.802, cx : 147.082, cy : 119.322, opacity : 1}, 1000, mina.easeInOutCubic).removeClass('animation-off');
		ring2.animate({r : 116.802, cx : 119.322, cy : 160.574, opacity : 1}, 1000, mina.easeInOutCubic).removeClass('animation-off');
		ring3.animate({r : 116.802, cx : 173.972, cy : 159.4, opacity : 1}, 1000, mina.easeInOutCubic).removeClass('animation-off');
	}
	else {
		ring1.animate({cy : 119.322 - blendInRadius, opacity : 0}, 1000, mina.easeInOutCubic);
		ring2.animate({cx : 119.322 - blendInRadius, opacity : 0}, 1000, mina.easeInOutCubic);
		ring3.animate({cx : 173.972 + blendInRadius, opacity : 0}, 1000, mina.easeInOutCubic);
	}


};

var showText = function (el) {
	for (var x = 1; x < 4; x++) {

		if (jQuery("#ring-copy-" + x).length == 0) {
			jQuery("#intro-rings").append(jQuery(".owl-ring-" + x + " .ring-copy").clone().attr("id", "ring-copy-" + x));
			jQuery("#intro-rings").append(jQuery(".owl-ring-" + x + " .ring-paragraph").clone().attr("id", "ring-paragraph-" + x));
		}
		positionTextById(x);
	}
};

var positionTextById = function (id) {
	var ring = jQuery("#ring" + id);
	var copy = jQuery("#ring-copy-" + id);
	var paragraph = jQuery("#ring-paragraph-" + id);

	var circleRect = document.getElementById("ring" + id).getBoundingClientRect();
	var ringsRect = document.getElementById("intro-rings").getBoundingClientRect();

	var xPos = circleRect.left - ringsRect.left;
	var yPos = circleRect.top - ringsRect.top;

	copy.css({width : (circleRect.width), overflow : "show", height : circleRect.height, top : yPos, left : xPos}).addClass("is-visible");
	paragraph.css({width : (circleRect.width * 1.4), overflow : "show", top : yPos * 2, left : xPos}).fadeIn();
};

var hideText = function () {
	jQuery(".intro .ring-copy").removeClass("is-visible");
	jQuery(".ring-paragraph").fadeOut();
};
